<template>
  <b-sidebar
    :visible="isAddNewEntitySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    
    @change="(val) => $emit('update:is-add-new-entity-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add New Entity Instance</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
     
        >
            <contilt-inputs
            ref="contilt-inputs"
            :title="entityData.name"
            generateLabel="Add"
            :generationLoading="addingLoading"
  

           
            :sampleinputs="sampleinputs"
            ></contilt-inputs> 
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="addNewEntityInstance"
            >
            Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormText,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import contiltInputs from "../../../main/inputsFactory/inputsFactory.vue"
import axios from '@axios';
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormText,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    contiltInputs
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewEntitySidebarActive",
    event: "update:is-add-new-entity-sidebar-active",
  },
  props: {
    isAddNewEntitySidebarActive: {
      type: Boolean,
      required: true,
    },
    entityData: {
      type: Object,
      required: false,
    }
 
  },
  data() {
    return {
      sampleinputs: null,
      entityTemplateId: null,
      addingLoading: false,
      resultsData: {}
    };
  },

  created() {
    this.sampleinputs = null;
    this.entityTemplateId = null;
    axios.get(`templates/`, {params: {namespace: this.entityData.entityId, limit: 10, selectedFields: "id inputs", onlyTemplates: 1, templateTypes: "ENTITY"}}).then(res => {
        this.sampleinputs = res.data.templates.results[0].inputs;
        this.entityTemplateId = res.data.templates.results[0].id;
    })
   
  },
  methods: {
    addNewEntityInstance(){
        
    },

    onSubmit() {
        let self = this;
        let data = {};
        const resultsData = this.$refs["contilt-inputs"].resultsData
        Object.keys(resultsData).forEach((key) => {
            data[key] = resultsData[key] || "";
        });
        data["_entityKeyId"] = this.entityData.entityKeyId;
        const dataToSend = {
            templateId: this.entityTemplateId,
            context: data,
            useSocket: false,
        };
        axios
          .post("/templates/run", dataToSend)
          .then((response) => {
    

            this.addingLoading = false;
            //update credits
            self.isAddNewEntitySidebarActive = false;
            this.$emit('refetch-data')
          })
          .catch((error) => {
            this.addingLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Error happened",
                icon: "AlertTriangleIcon",
                variant: "light-danger",
                text: `Something went wrong`,
              },
            });
          });


      
    },
  },

};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
