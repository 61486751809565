<template>
  <div>
    <pagination
      :dataMeta="dataMeta"
      :currentPage="currentPage"
      :totalData="totalData"
      v-on:pagination:change="(value) => updatePagination(value)"
    >
    </pagination>
  </div>
</template>

<script>
import Pagination from "../../../dashboard/components/pagination/pagination.vue";

export default {
  components: { Pagination },
  props: ["dataMeta", "currentPage", "totalData"],
  methods: {
    updatePagination(value) {
      this.$emit("pagination:change", value);
    },
  },
};
</script>
