import axios from "@axios";
// import { entityList } from "../../contilt/configs/examples";
import {
  entityList,
  entityObject,
} from "../../../../../contilt/configs/examples";

export function loadListData(data) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/entity-instances`,{params: data})
      .then(
        (response) => {
            resolve(response);
          }
      ).catch((e) => {
        reject(e);
      });

    // setTimeout(() => {
    //   resolve(entityList);
    // }, 2000);
  });
}

export function loadEntityData(data) {
  return new Promise((resolve, reject) => {
    // axios
    //   .get('',data)
    //   .then(
    //     (response) => {
    //         resolve(response);
    //       }
    //   ).catch((e) => {
    //     reject(e);
    //   });

    setTimeout(() => {
      resolve(entityObject);
    }, 2000);
  });
}

export function createNewEntity(data) {
  return new Promise((resolve, reject) => {
    // axios
    //   .get('',data)
    //   .then(
    //     (response) => {
    //         resolve(response);
    //       }
    //   ).catch((e) => {
    //     reject(e);
    //   });

    setTimeout(() => {
      resolve({});
    }, 2000);
  });
}
