<template>
  <div class="auth-wrapper navbar-container d-flex content align-items-center">
      <b-link class="alert-link" :to="{ name: 'templates-home-page'}">
      
                  <b-img
            :src="organizationAvatar || require('@/assets/images/logo/contilt-small.png')"
            class="mr-1"
       
            width="120"
            alt="Toast image"
          />
      
      </b-link>
    <!-- Nav Menu Toggler -->
 

    <!-- Left Col -->
     <!-- Bookmarks Container -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

     
      <bookmarks />
    </div> -->

    <b-navbar-nav class="nav align-items-center ml-auto">
       <b-form-tag :key="index" v-for="(instance, index) in selectedInstances"
              
          
                variant="primary"
                @remove="deleteStelectedEntity(instance.entityKeyId)"
              >
               {{instance.name}}
              </b-form-tag>
      <entity-show class="d-none d-lg-block" />
      <!-- <locale /> -->
      <dark-Toggler class="d-none d-lg-block" />
      <!-- <search-bar />-->
      <!-- <cart-dropdown />  -->
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BImg, BFormTag
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import EntityShow from './components/EntityShow.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import { mapState, mapActions } from 'vuex';
export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    EntityShow,
    NotificationDropdown,
    UserDropdown,
    BImg,
    BFormTag
  },
  computed:{
        ...mapState({
            selectedInstances: state => state.entityStore.selectedInstances,
        }),
        organizationAvatar() {
          return this.$store.state.authStore.currentUser && this.$store.state.authStore.currentUser.organization ? this.$store.state.authStore.currentUser.organization.avatar: null;
        },
  },

  methods: {
    ...mapActions('entityStore', {
        deleteStelectedEntity: "deleteStelectedEntity",
    }),

  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
