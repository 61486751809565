<template>
  <b-nav-item @click="showEntityModal()">
    <feather-icon
      size="21"
      icon="GridIcon"
    />
  <b-modal
    
    class="entity-modal"
      ref="entity-modal"
      title="Entities"
       size="lg"
      ok-only
      ok-variant="dark"
      ok-title="Cancel"
      centered
    >
    <b-tabs
        vertical
        nav-wrapper-class="nav-vertical"
      >
        <b-tab
          :key="entity.entityKeyId"
          v-for="entity in entities"
          :active="entity.active"
          :title="entity.name"
        >
          <entities-list :name="entity.name" :entityId="entity.entityId" :entityKeyId="entity.entityKeyId"></entities-list>
        </b-tab>
        
      </b-tabs>
    </b-modal>
  </b-nav-item>

</template>

<script>

import { BNavItem, BModal, BTabs, BTab } from 'bootstrap-vue';
import EntitiesList from "../../../../../views/pages/contilt-pages/entities/entitiesList/entitieslist.vue";

import axios from "@axios"
export default {
  components: {
    EntitiesList,
    BNavItem,
    BModal,
    BTabs, BTab,
  },
  data() {
    return{
      entities: [],
    }
  },
  methods: {
    showEntityModal() {
      this.$refs["entity-modal"].show();
      this.getEntities();
    },
    getEntities(){
      axios.get("/entity-keys", {params: {selectedFields: "name entityId"}}).then(res => {
        this.entities = res.data.results.map(x=> {
          return {
            name: x.name,
            entityKeyId: x.id,
            entityId: x.entityId,
          }
        });
        this.entities[0].active = true;
      })
    }
  }
 
}
</script>
<style>
  .modal-lg {
    max-width: 1000px !important;
  }
</style>