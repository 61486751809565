<template>
  <section id="knowledge-base-content" v-if="listData && listData.length > 0">
    <!-- content -->
    <b-row class="kb-search-content-info match-height">
      <b-col
        v-for="(item, index) in listData"
        :key="item.id"
        md="4"
      
        class="kb-search-content"
      >
        <b-card
          class="entity-instance text-center cursor-pointer"
          no-body
          @click="() => cardClick(item)"
        >
          <b-card-header>
            <div class="d-flex align-items-center">
            
              <h6 class="mb-0">{{ item.name }}</h6>
            </div>
          </b-card-header>
          <b-card-text>
            {{ item.description }}
          </b-card-text>
    <b-button
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      variant="flat-danger"
      class="btn-icon rounded-circle removeInstance"
      @click.stop="deleteInstance(item,index)"
    >
      <feather-icon icon="XIcon" />
    </b-button>
        </b-card>
        
      </b-col>
      <b-col v-show="!listData.length" cols="12" class="text-center">
        <h4 class="mt-4">Search result not found!!</h4>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BCardHeader,
  BCol,
  BRow,
  BAvatar,
  BCardText,
  BButton
  // BDropdownItem,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from '@axios';

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCol,
    BRow,
    BAvatar,
    BCardText,
    BButton
    // BDropdownItem,
  },
  props: ["dataToDisplay", "entityKey"],

  methods: {
    deleteInstance(instance, index) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure that you want to delete the selected instance [${instance.name}]?`,
          {
            title: "Delete Intance",
            size: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            
            axios.post(`entity-instances/deleteEntities`, [instance.id]).then(() => {
              this.$emit("delete-instance")
            })
          }
        });
    },
    cardClick(item) {
      this.$store.dispatch("entityStore/updateStateEntiity", {
        key: this.entityKey,
        value: item,
      });
    
    },
  },
  data() {
    return {
      defaultAvatar: "/img/sales.1172632b.svg",
    };
  },
  computed: {
    listData() {
      const list = this.dataToDisplay || [];
      return list;
    },
  },
};
</script>

<style scoped>
  
  .entity-instance:hover {
  padding-left: 10px;
}

.entity-instance:hover {
  background-color: rgb(240, 240, 240) !important;
}
.removeInstance {
  position: absolute !important;
  right: 0px;
  top: 0px;
}
</style>