<template>
  <div>
    <div v-if="!loading">
    <entity-instance-list-add-new
      v-if="isAddNewEntitySidebarActive"
      :is-add-new-entity-sidebar-active.sync="isAddNewEntitySidebarActive"
      @refetch-data="refetchData"
      :entityData="{entityId: entityId, entityKeyId: entityKeyId}"
    />
      <div>
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col lg="8" >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filters.q"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
          <b-col lg="2">
            <b-button class="add-entity-btn" variant="primary" @click="createEntity()">
              <span class="text-nowrap">Add {{name}}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <!-- <b-input-group  class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input placeholder="Search" />
          </b-input-group> -->

      <entities-list-renderer
        style="margin-top: 20px"
        :dataToDisplay="dataToDisplay"
        :entityKey="entityKeyId"
        @delete-instance="refetchData"
      ></entities-list-renderer>

        <b-pagination
            v-model="filters.page"
            :total-rows="totalInstances"
            :per-page="filters.limit"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
        </b-pagination>


    </div>

    <div v-else class="d-flex align-items-center justify-content-center">
      <b-spinner type="grow" label="Loading..." variant="primary" />
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BSpinner,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BPagination
} from "bootstrap-vue";
import EntitiesListHeader from "../components/entitieslistheader/entitieslistheader.vue";
import EntitiesListRenderer from "../components/entitieslistbody/entitiesrenderer.vue";
import {
  loadListData,
} from "../entitiesServices/entity.service";
import EntitiesPaginationFooter from "../components/entitiesPaginationFooter/entitiespaginationfooter.vue";
import EntityInstanceListAddNew from "./EntityInstanceListAddNew.vue"
export default {
  props: ["entityKeyId", "entityId", "name"],
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
    EntitiesListHeader,
    EntitiesListRenderer,
    EntitiesPaginationFooter,
    BButton,
    EntityInstanceListAddNew,
    BPagination
  },
  data() {
    return {
      loading: false,
      laodingError: false,
      dataToDisplay: [],
      entity: {},
      currentPage: 1,
      isAddNewEntitySidebarActive: false,
      
      filters: {
        limit: 15,
        q: "",
        page: 0
      },
      totalInstances: null,

    };
  },
  created() {
    this.loading = true;
    this.laodingError = false;
    this.load();

  
  },
  watch: {
    "filters": {
      deep: true,
      handler(newConfig) {
        this.load(newConfig);
      }

    }
  },

  methods: {
    refetchData(){
      this.load(this.filters);
    },

    createEntity() {
      this.isAddNewEntitySidebarActive = true;
    },
    updatePagination(value) {
      this.currentPage = value;
      //call api to fetch new data to show =  load()..
    },
    load(data) {
      if(!data){
        data = {};
      }
      loadListData({
        ...data,
        entityId: this.entityId,
        entityKeyId: this.entityKeyId,
        sortBy: "-createdAt",
        limit: 15,
        selectedFields: "name values entityId entityKeyId"
      })
        .then((response) => {
          this.loading = false;
          this.laodingError = false;
          this.dataToDisplay = response.data.results;
          this.totalInstances = response.data.totalResults
        })
        .catch((e) => {
          console.log(e);
          this.laodingError = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style  scoped>
  .add-entity-btn {
    width: 245px;
    max-width: 245px;
  }
</style>
