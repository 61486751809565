<template>
  <section id="knowledge-base-search2">
    <b-card
      no-body
      class="knowledge-base-bg text-center"
      :style="{
        backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`,
      }"
    >
      <b-card-body class="card-body">
        <h2 class="text-primary">{{ headerTitle }}</h2>
        <b-card-text class="mb-2">
          <span>{{ subTitle }} </span>
        </b-card-text>

        <b-form class="kb-search-input">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="searchbar"
              @input="debounceSearch"
              v-model="searchTerm"
              placeholder="Search.."
            />
          </b-input-group>
        </b-form>

        <b-card-text class="mt-4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :to="{ name: 'create-new-entity', query: { entityKey, entityId } }"
          >
            {{ submitButtonText }}
          </b-button>
        </b-card-text>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BForm,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BInputGroupPrepend,
  },
  props: ["headerTitle", "subTitle", "submitButtonText", "load"],

  data() {
    return {
      searchTerm: this.defaultValue || "",
      debounce: "",
    };
  },
  methods: {
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$router.push({
          // path: "/history/",
          query: {
            ...this.$router.history.current.query,
            search: this.searchTerm,
          },
        });
        //search api call
        // this.load(this.searchTerm);
      }, 400);
    },
  },

  computed: {
    entityKey() {
      return this.$route.query.entityKey;
    },
    entityId() {
      return this.$route.query.entityId;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-knowledge-base.scss";
</style>
